import React from "react";
import {NavLink, useLocation} from "react-router-dom";
import Container from '../../DSL/Container/Container';
import {css} from '../../helpers/css';
import Colors from '../../DSL/Colors';
import Typography, {TType} from '../../DSL/Typography/Typography';
import {useAppStore} from '../../store/App.store';
import styles from './Sidebar.module.scss';
import Icon, {IconName} from '../../DSL/Icon/Icon';
import observeMobx from '../../observeMobx';
import {route, NamedRoutes} from '../../App.routes';
import {Roles} from '../../types';

export type SideBarItem = {
    label: string,
    route: string,
    icon: IconName,
    role: Roles // the roles with permission 
}

export const NavBarConfigs: SideBarItem[] = [
  {
    label: 'Dashboard',
    route: 'DASHBOARD',
    icon: 'dashboard',
    role: Roles.standard
  },
  {
    label: 'Coaches',
    route: 'COACHES',
    icon: 'user-alt',
    role: Roles.manageCoaches
  },
  {
    label: 'Athletes',
    route: 'ATHLETES',
    icon: 'running',
    role: Roles.manageAthletes
  },
  {
    label: 'Billing',
    route: 'BILLING',
    icon: 'credit-card',
    role: Roles.billing
  },
  {
    label: 'Settings',
    route: 'SETTINGS',
    icon: 'cog',
    role: Roles.standard
  }
]

const NavItem = ({item}: {item: SideBarItem}) => {
    // @ts-ignore
    const navRoute = route(item.route);
    const activePage = useLocation().pathname;
    return <div className={css('mt-3', 'pl-3', 'py-2', 'pr-5', Colors.hover.bg.action, Colors.color.defaultText, Colors.hover.color.white, {
      [Colors.bg.action]: navRoute === activePage,
      [Colors.color.white]: navRoute === activePage
    })}> 
        <NavLink to={navRoute} className={css('flex-row', 'align-items-center', 'd-flex', Colors.hover.color.white)}>
            <Icon icon={navRoute === activePage ? item.icon : `${item.icon}-light` as IconName}/>
            <Typography className={css( 'ml-3')} type={navRoute === activePage ? TType.BodyBold : TType.BodyRegular} size={15}>
              {item.label}
            </Typography>
        </NavLink> 
    </div>
}

const AppDefaultLayout = observeMobx((props: any) => {
    const app = useAppStore();
    return (
        <div className={css('h-full', styles['sidebar'], 'd-flex', 'flex-column', 'justify-content-between', Colors.bg.pane)}>
            <div>
              <Typography className={css('mt-4', 'ml-3')} type={TType.HeaderExtraBold} size={25}>ATHPRO</Typography>
              <div className={css('mt-4')}>
                {NavBarConfigs.map((item: SideBarItem, index: number) => {
                  const permission = app.user.roles.includes(item.role);
                  if (permission) return <NavItem item={item} key={index}/>;
                })}
              </div>
            </div>
        </div>
    );
});
export default AppDefaultLayout;