import React from "react";
import classNames from "classnames";
import styles from './Settings.module.scss';
import {css} from '../../helpers/css';
import Typography, {TType} from '../../DSL/Typography/Typography';
import observeMobx from '../../observeMobx';
import {useAppStore} from '../../store/App.store';
import Colors from '../../DSL/Colors';
import Header from '../../DSL/Header/Header';
import Pane from '../../DSL/Pane/Pane';
import Row from '../../DSL/Row/Row';
import Col from '../../DSL/Col/Col';
import Icon from '../../DSL/Icon/Icon';

export interface SettingsProps {

}

const Settings = observeMobx((props: SettingsProps) => {
    const app = useAppStore();
    return (
      <>
        <Header title="SETTINGS" />
          <Row className={css('mt-3')}>
            <Col sm={2}>
              <Pane onClick={app.logout} className={css(Colors.bg.pane, 'd-flex', 'justify-content-between', 'align-items-center', 'px-3', 'hover')}>
                <Icon icon='sign-in' />
                <Typography type={TType.HeaderExtraBold}>LOGOUT</Typography>
              </Pane>
            </Col>
          </Row>
      </>
    )
})
export default Settings;