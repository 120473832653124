import React, {ComponentClass} from "react";
import {Route} from "react-router-dom";
import {ItsA_R_e_a_c_t_Component} from "../helpers/interfaces";
import {RouteMiddleware} from "./AuthMiddleware";

export interface AppRouteProps {
    component: ItsA_R_e_a_c_t_Component;
    layout: ItsA_R_e_a_c_t_Component;
    exact?: boolean
    path?: string;
    guest?: any
    middleware?: RouteMiddleware;
}

const AppRoute = ({component: Component, layout: Layout, middleware, ...rest}: AppRouteProps) => {
  const defaultRender = <Route {...rest} render={props => (
    <Layout>
        <Component {...props} />
    </Layout>
  )}/>;

  if (middleware) {
    return middleware(defaultRender);
  } else {
    return defaultRender;
  }

};
export default AppRoute;