import * as React from 'react';
import {DEFAULT, TypeOptions} from "../utils/constant";
import Icon from "../../Icon/Icon";
import classNames from "classnames";

export interface CloseButtonProps {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
  type: TypeOptions;
  ariaLabel?: string;
}

export function CloseButton({
  closeToast,
  type,
  ariaLabel = 'close'
}: CloseButtonProps) {
  return (
    <button
      className={classNames(`${DEFAULT.CSS_NAMESPACE}__close-button ${DEFAULT.CSS_NAMESPACE}__close-button--${type}`,
          'd-none',
          'pr-2')}
      type="button"
      onClick={e => {
        e.stopPropagation();
        closeToast(e);
      }}
      aria-label={ariaLabel}
    >
      <Icon icon={"cross"}/>
    </button>
  );
}
