import React, {useEffect, useMemo} from "react";
import classNames from "classnames";
import styles from './Coaches.module.scss';
import {css} from '../../helpers/css';
import Typography, {TType} from '../../DSL/Typography/Typography';
import observeMobx from '../../observeMobx';
import {useAppStore} from '../../store/App.store';
import Colors from '../../DSL/Colors';
import Header from '../../DSL/Header/Header';
import Input from '../../DSL/Input/Input';
import Button from '../../DSL/Button/Button';
import CoachesStore from './Coaches.store';
import {Table} from 'react-bootstrap';
import Modal from '../../DSL/Modal/Modal';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import moment from 'moment';

export interface CoachesProps {

}

const Coaches = observeMobx((props: CoachesProps) => {
  const app = useAppStore();
  const store = useMemo(() => new CoachesStore(), []);
  return (
    <>
      <Header title='COACHING TEAM' centerComponent={() => (
        <div className={css('d-flex')}>
          <Input className={css('px-4')} value={store.searchValue} onChange={store.setSearchValue} placeholder='Search Coaches' />
          <Button onClick={() => store.setAddNewModal(true)} className={css('px-4', 'ml-4')}>ADD NEW COACH</Button>
        </div>
      )} />
      <Table className={css('mt-5')}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Last Login</th>
          </tr>
        </thead>
        <tbody>
          {store.coaches.map((user, index: number) => (
            <tr key={index}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.lastLogin ? moment(user.lastLogin).fromNow() : ''}</td>
            </tr> 
          ))}
        </tbody>
      </Table>
      <Modal visible={store.addNewModal} setVisible={store.setAddNewModal} title="Add Coach" footer={() => (
        <div className={css('d-flex', 'justify-content-end')}>
          <Button onClick={store.sendInvite} className={css('px-3')} size='md'>SEND INVITE</Button>
        </div>
      )}>
        <div className={css('d-flex', 'mt-3', 'justify-content-between')}>
          <Input className={css(styles['new-input'])} size="md" value={store.newCoachName} onChange={store.setNewCoachName} placeholder='Full Name' />
          <Input className={css(styles['new-input'])} size="md" value={store.newCoachEmail} onChange={store.setNewCoachEmail} placeholder='Email' />
        </div>
        <div className={css('d-flex', 'align-items-center', 'mt-4')}>
          <Toggle checked={store.newCoachManageAthletes} onChange={store.toggleNewCoachManageAthletes}/>
          <Typography className={css('ml-2')} type={TType.BodyRegular}>Manage Athletes</Typography>
        </div>
        <div className={css('d-flex', 'align-items-center', 'mt-3')}>
          <Toggle checked={store.newCoachManageCoaches} onChange={store.toggleNewCoachManageCoaches}/>
          <Typography className={css('ml-2')} type={TType.BodyRegular}>Manage Coaches</Typography>
        </div>
        <div className={css('d-flex', 'align-items-center', 'mt-3', 'mb-4')}>
          <Toggle checked={store.newCoachBilling} onChange={store.toggleNewCoachBilling}/>
          <Typography className={css('ml-2')} type={TType.BodyRegular}>Billing Access</Typography>
        </div>
      </Modal>
    </>
  )
})
export default Coaches;