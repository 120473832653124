import {generatePath} from "react-router";
import React, {ReactNode} from "react";
import { useHistory } from "react-router-dom";
import AppDefaultLayout from "./layouts/AppDefault/AppDefaultLayout";
import DSLPage from "./pages/dsl/dsl.page";
import {ItsA_R_e_a_c_t_Component} from "./helpers/interfaces";
import AuthLayout from './layouts/Auth/AuthLayout';
import LoginPage from './pages/Login/Login';
import CreateOrgPage from './pages/CreateOrg/CreateOrg';
import EmptyLayout from './layouts/Empty/EmptyLayout';
import LandingPage from './pages/Landing/Landing';
import DashboardPage from './pages/Dashboard/Dashboard';
import AuthMiddleware, {RouteMiddleware} from './common/AuthMiddleware';
import AppLayout from './layouts/AppLayout/AppLayout';
import CoachesPage from './pages/Coaches/Coaches';
import AthletesPage from './pages/Athletes/Athletes';
import BillingPage from './pages/Billing/Billing';
import SettingsPage from './pages/Settings/Settings';
import AcceptInvitePage from './pages/AcceptInvite/AcceptInvite';

export enum NamedRoutes {
    HOME = 'HOME',
    DSL = 'DSL',
    LOGIN = 'LOGIN',
    CREATE_ORG = 'CREATE_ORG',
    LANDING = 'LANDING',
    DASHBOARD = 'DASHBOARD',
    COACHES = 'COACHES',
    ATHLETES = 'ATHLETES',
    BILLING = 'BILLING',
    SETTINGS = 'SETTINGS',
    ACCEPT_INVITE = 'ACCEPT_INVITE'
}

export interface AppRouteInterface {
    path: string;
    exact: boolean;
    label?: string;
    name?: NamedRoutes;
    layout: ItsA_R_e_a_c_t_Component;
    component: ItsA_R_e_a_c_t_Component
    guest?: boolean;
    middleware?: RouteMiddleware;
}

export const route = (name: NamedRoutes, params: any = {}) => {
    const route = routes.find(item => item.name === name);
    if (!route) {
        throw new TypeError("Unknown named route: " + name);
    }
    if (params) {
        return generatePath(route.path, params);
    } else {
        return route.path
    }
};
export const redirect = (history: any, name: NamedRoutes, params: any = {}, search: { [key: string]: string } = {}) => {
    return history.push({
        pathname: route(name, params),
        search: (new URLSearchParams(search)).toString()
    });
};

export function getQueryParams(history: ReturnType<typeof useHistory>) {
    return new URLSearchParams(history.location.search);
}

export function getQueryParam(history: ReturnType<typeof useHistory>, key: string) {
    return getQueryParams(history).get(key);
}

export function setQueryParam(history: ReturnType<typeof useHistory>, key: string, val: string | undefined) {

    let searchParams = getQueryParams(history);
    if (val === undefined) {
        searchParams.delete(key);
    } else {
        searchParams.set(key, val);
    }
    history.push({
        pathname: history.location.pathname,
        search: searchParams.toString()
    });
}


const routes: AppRouteInterface[] = [
  {
    path: "/",
    name: NamedRoutes.LANDING,
    label: "LANDING",
    exact: true,
    layout: EmptyLayout,
    component: LandingPage
  },
  {
    path: "/login",
    name: NamedRoutes.LOGIN,
    label: "LOGIN",
    exact: true,
    layout: AuthLayout,
    component: LoginPage
  },
  {
    path: "/create-org",
    name: NamedRoutes.LOGIN,
    label: "LOGIN",
    exact: true,
    layout: AuthLayout,
    component: CreateOrgPage
  },
  {
    path: "/create-org",
    name: NamedRoutes.LOGIN,
    label: "LOGIN",
    exact: true,
    layout: AuthLayout,
    component: CreateOrgPage
  },
  {
    path: "/dashboard",
    name: NamedRoutes.DASHBOARD,
    label: "DASHBOARD",
    exact: true,
    layout: AppLayout,
    component: DashboardPage,
    middleware: AuthMiddleware
  },
  {
    path: "/coaches",
    name: NamedRoutes.COACHES,
    label: "COACHES",
    exact: true,
    layout: AppLayout,
    component: CoachesPage,
    middleware: AuthMiddleware
  },
  {
    path: "/athletes",
    name: NamedRoutes.ATHLETES,
    label: "ATHLETES",
    exact: true,
    layout: AppLayout,
    component: AthletesPage,
    middleware: AuthMiddleware
  },
  {
    path: "/billing",
    name: NamedRoutes.BILLING,
    label: "BILLING",
    exact: true,
    layout: AppLayout,
    component: BillingPage,
    middleware: AuthMiddleware
  },
  {
    path: "/settings",
    name: NamedRoutes.SETTINGS,
    label: "SETTINGS",
    exact: true,
    layout: AppLayout,
    component: SettingsPage,
    middleware: AuthMiddleware
  },
  {
    path: "/accept-invite/:inviteId",
    name: NamedRoutes.ACCEPT_INVITE,
    label: "ACCEPT_INVITE",
    exact: true,
    layout: AuthLayout,
    component: AcceptInvitePage
  },
];
export default routes;