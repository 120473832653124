import React, {FC} from "react";
import classNames from "classnames";
import styles from './Header.module.scss';
import {css} from '../../helpers/css';
import Typography, {TType} from '../Typography/Typography';
import observeMobx from '../../observeMobx';
import {useAppStore} from '../../store/App.store';
import Colors from '../../DSL/Colors';

export interface HeaderProps {
  title: string,
  centerComponent?: any
}

const Header = observeMobx((props: HeaderProps) => {
    const app = useAppStore();
    return (
      <div className={css('w-full', 'd-flex', 'justify-content-between', 'align-items-center', 'mt-4')}>
        <Typography type={TType.HeaderExtraBold} size={25}>{props.title}</Typography>
        {props.centerComponent && <props.centerComponent/>}
        <div style={{lineHeight: 1}} className={css('text-align-right')}>
          <Typography type={TType.BodyBold}>{app.user.name}</Typography><br/>
          <Typography secondary type={TType.BodyRegular}>{app.user.email}</Typography>
        </div>
      </div>
    )
})
export default Header;