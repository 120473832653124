export enum UserTypes { 
  athlete = 'athlete',
  coach = 'coach'
}

export enum Roles {
  standard = 'standard',
  billing = 'billing',
  manageCoaches = 'manageCoaches',
  manageAthletes = 'manageAthletes'
}

export enum UserStatus {
  active = 'active',
  suspended = 'suspended'
}

export type User = {
  id: string,
  createdAt: Date,
  name: string,
  email: string,
  userType: UserTypes,
  roles: Roles[],
  accessToken: string,
  lastLogin?: Date
}

export const emptyUser: User = {
  id: '',
  createdAt: new Date(),
  name: '',
  email: '',
  userType: UserTypes.coach,
  roles: [Roles.standard],
  accessToken: ''
}

export type Org = {
  id: string,
  createdAt: Date,
  name: string,
  coaches: string[],
  athletes: string[]
}

export const emptyOrg: Org = {
  id: '',
  createdAt: new Date(),
  name: '',
  coaches: [],
  athletes: []
}