import React from "react";
import {NavLink} from "react-router-dom";
import Container from '../../DSL/Container/Container';
import {css} from '../../helpers/css';
import Colors from '../../DSL/Colors';
import Typography, {TType} from '../../DSL/Typography/Typography';
import Button from '../../DSL/Button/Button';

const AppDefaultLayout = (props: any) => {
    //const store = useGlobalStore();
    return (
        <Container>
          {props.children}
        </Container>
    );
};
export default AppDefaultLayout;