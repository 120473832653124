import React, {useMemo} from "react";
import classNames from "classnames";
import styles from './CreateOrg.module.scss';
import {css} from '../../helpers/css';
import Typography, {TType} from '../../DSL/Typography/Typography';
import observeMobx from '../../observeMobx';
import {useAppStore} from '../../store/App.store';
import Colors from '../../DSL/Colors';
import Input from '../../DSL/Input/Input';
import CreateOrgStore from './CreateOrg.store';
import Button from '../../DSL/Button/Button';
import {NavLink} from 'react-router-dom';

export interface CreateOrgProps {

}

const CreateOrg = observeMobx((props: CreateOrgProps) => {
    const app = useAppStore();
    const store = useMemo(() => new CreateOrgStore(), []);
    return (
      <div>
        <Typography type={TType.HeaderExtraBold} size={25}>ATHPRO</Typography><br/>
        <Typography secondary type={TType.BodyRegular}>If you are trying to join an existing organization please request an invite<br/>from your organizations account manager.</Typography><br/>
        {store.view === 'info' ? <>
          <div className={styles['body']}>
          <Input placeholder="Email Address" value={store.email} onChange={store.setEmail} size='md' className={css('w-full', 'mt-3')} />
          <Input placeholder="Password" type="password" value={store.password} onChange={store.setPassword} size='md' className={css('w-full', 'mt-2')} />
          <Input placeholder="Full Name" value={store.name} onChange={store.setName} size='md' className={css('w-full', 'mt-2')} />
          <Input placeholder="Organization Name" value={store.orgName} onChange={store.setOrgName} size='md' className={css('w-full', 'mt-2')} />
        </div>
        <div className={css('d-flex', 'mt-4')}>
          <Button onClick={store.verifyEmail} className={css('px-5')}>CREATE ACCOUNT</Button>
          <NavLink to='/login'>
            <Button secondary className={css('px-5', 'ml-2')}>LOGIN</Button>
          </NavLink>
        </div>
      </> : <>
        <div className={styles['body']}>
          <Input placeholder="Email Code" value={store.emailCode} onChange={store.setEmailCode} size='md' className={css('w-full', 'mt-3')} />
        </div>
        <div className={css('d-flex', 'mt-3')}>
          <Button onClick={store.create} className={css('px-5')}>VERIFY</Button>
          <Button onClick={() => store.setView('info')} secondary className={css('px-5', 'ml-2')}>GO BACK</Button>
        </div>
      </>}
      </div>
    )
})
export default CreateOrg;