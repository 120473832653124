import {action, computed, observable, makeObservable} from 'mobx';
import { showToast, ToastType } from '../../DSL/Toast';
import api from '../../services/api';
import {AppStore} from '../../store/App.store';
import { Roles, User } from '../../types';

class Store {

  @observable
  searchValue: string = '';

  @observable
  addNewModal: boolean = false;

  @observable
  newCoachName: string = '';

  @observable
  newCoachEmail: string = '';

  @observable
  newCoachManageAthletes: boolean = false;

  @observable
  newCoachManageCoaches: boolean = false;

  @observable
  newCoachBilling: boolean = false;

  @observable
  coaches: User[] = [];

  constructor () {
    makeObservable(this);
    this.getCoaches();
  }

  @action
  getCoaches = () => {
    api('/org/coaches').then(action(res => {
      if (res.message) showToast('Error', res.message, ToastType.ERROR);
      else this.coaches = res.result.coaches;
    }))
  }

  @action
  setSearchValue = (value: string) => this.searchValue = value;

  @action
  setAddNewModal = (status: boolean) => this.addNewModal = status;

  @action
  setNewCoachName = (value: string) => this.newCoachName = value;

  @action
  setNewCoachEmail = (value: string) => this.newCoachEmail = value;

  @action
  toggleNewCoachManageAthletes = () => this.newCoachManageAthletes = !this.newCoachManageAthletes;

  @action
  toggleNewCoachManageCoaches = () => this.newCoachManageCoaches = !this.newCoachManageCoaches;

  @action
  toggleNewCoachBilling = () => this.newCoachBilling = !this.newCoachBilling;

  @action
  sendInvite = () => {
    this.addNewModal = false;
    if (this.newCoachName === '') showToast('Name required', 'Enter a coach name');
    else if (this.newCoachEmail === '') showToast('Email required', 'Enter a valid email');
    else {
      const roles: Roles[] = [Roles.standard];
      if (this.newCoachManageAthletes) roles.push(Roles.manageAthletes);
      if (this.newCoachManageCoaches) roles.push(Roles.manageCoaches);
      if (this.newCoachBilling) roles.push(Roles.billing);
      api('/send-invite/coach', {
        method: "POST",
        body: {
          org: AppStore.org.id,
          name: this.newCoachName,
          email: this.newCoachEmail,
          roles,
          callbackUrl: window.location.origin
        }
      }).then(action(res => {
        if (res.message) showToast('Error', res.message, ToastType.ERROR);
        else {
          showToast('Success', 'Invite has been sent', ToastType.SUCCESS);
          this.newCoachBilling = false;
          this.newCoachEmail = '';
          this.newCoachName = '';
          this.newCoachManageAthletes = false;
          this.newCoachManageCoaches = false;
        }
      }))
    }
  }

}

export default Store;