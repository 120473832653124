import React, { useMemo } from "react";
import classNames from "classnames";
import styles from './Login.module.scss';
import {css} from '../../helpers/css';
import Typography, {TType} from '../../DSL/Typography/Typography';
import observeMobx from '../../observeMobx';
import {useAppStore} from '../../store/App.store';
import Colors from '../../DSL/Colors';
import Input from '../../DSL/Input/Input';
import LoginStore from './Login.store';
import Button from '../../DSL/Button/Button';
import {NavLink} from 'react-router-dom';

export interface LoginProps {

}

const Login = observeMobx((props: LoginProps) => {
    const app = useAppStore();
    const store = useMemo(() => new LoginStore(), []);
    return (
      <div className={styles['body']}>
        <Typography type={TType.HeaderExtraBold} size={25}>ATHPRO</Typography><br/>
        <Input className={css('mt-3', 'w-full')} size='md' onChange={store.setEmail} value={store.email} placeholder='Email Address'/><br/>
        <Input className={css('mt-2', 'w-full')} type="password" size='md' onChange={store.setPassword} value={store.password} placeholder='Password'/>
        <div className={css('d-flex', 'mt-4')}>
          <Button onClick={store.login} className={css('px-5')}>LOGIN</Button>
          <NavLink to='/create-org'>
            <Button secondary className={css('px-5', 'ml-2')}>CREATE ACCOUNT</Button>
          </NavLink>
        </div>
      </div>
    )
})
export default Login;