import {createContext, useContext} from 'react'
import {action, computed, makeObservable, observable} from 'mobx'
import routes, {AppRouteInterface, NamedRoutes} from '../App.routes';
import Colors from '../DSL/Colors';
import {User, Org, emptyOrg, emptyUser} from '../types';
import LocalStorage from '../services/Local-storage';
import api from '../services/api';
import {showToast, ToastType} from '../DSL/Toast';

export type API_LOGIN_RES = {
  user: User,
  org: Org
}

export class AppStoreClass {

  @observable
  user: User = emptyUser;

  @observable
  org: Org = emptyOrg;

  @observable
  isInit: boolean = true;

  constructor() {
    makeObservable(this)
  }

  init() {
    //@ts-ignore
    document.body.classList.add(Colors.theme.light);
    this.getInfo();
  }

  getInfo () {
    api('/my-info-web').then(action(res => {
      if (!res.message) {
        this.user = res.result.user;
        this.org = res.result.org;
      }
    }))
  }

  login = (res: API_LOGIN_RES) => {
    this.user = res.user;
    this.org = res.org;
    LocalStorage.setItem('athpro-access-token', res.user.accessToken);
    window.location.href = '/dashboard';
  }

  logout = () => {
    this.user = emptyUser;
    this.org = emptyOrg;
    localStorage.removeItem('athpro-access-token');
    window.location.href = '/';
  }

}


export const AppStoreContext = createContext<AppStoreClass>({} as AppStoreClass);
export const AppStoreProvider = AppStoreContext.Provider;
export const useAppStore = (): AppStoreClass => useContext(AppStoreContext);
export const AppStore = new AppStoreClass();