import React from "react";
import classNames from "classnames";
import styles from './Dashboard.module.scss';
import {css} from '../../helpers/css';
import Typography, {TType} from '../../DSL/Typography/Typography';
import observeMobx from '../../observeMobx';
import {useAppStore} from '../../store/App.store';
import Colors from '../../DSL/Colors';
import Header from '../../DSL/Header/Header';
import Row from '../../DSL/Row/Row';
import Col from '../../DSL/Col/Col';
import Pane from "../../DSL/Pane/Pane";
import Icon, { IconName } from '../../DSL/Icon/Icon';
import {NavLink} from 'react-router-dom';
import {route, NamedRoutes} from '../../App.routes';
import { Roles } from "../../types";

export interface TileProps {
  name: string,
  label: string,
  icon: IconName,
  pathname: string,
  role: Roles
}

const Tile = observeMobx((props: TileProps) => {
  const app = useAppStore();
  return app.user.roles.includes(props.role) ? (
    <Col className={css('mt-3')} sm={4}>
      <NavLink to={props.pathname} className={Colors.hover.color.defaultText}>
        <Pane className={css(Colors.bg.pane, 'text-center', 'py-4', Colors.hover.borderColor.action)}>
          <Icon className={css(styles[`${props.name}-icon`])} icon={props.icon} size="3x" /><br/>
          <Typography className={css('mt-2')} type={TType.HeaderExtraBold}>{props.label}</Typography>
        </Pane>
      </NavLink>
    </Col>
  ) : null
})

export interface StatProps {
  name: string,
  value: number,
  icon: IconName
}

const Stat = (props: StatProps) => (
  <Pane className={css(Colors.bg.pane, 'd-flex', 'align-items-center', 'justify-content-between', 'mt-3', 'px-4')}>
    <div>
      <Typography type={TType.BodyRegular}>{props.name}</Typography><br/>
      <Typography type={TType.BodyBold}>{props.value}</Typography><br/>
    </div>
    <Icon size="2x" icon={props.icon} className={css(Colors.color.action)} />
  </Pane>
)

export interface DashboardProps {

}

const Dashboard = observeMobx((props: DashboardProps) => {
    const app = useAppStore();
    return (
      <>
        <Header title="ADMIN DASHBOARD" />
        <Row className={css('d-flex', 'justify-content-between')}>
          <Col className={css('mt-4')} sm={7}>
            <Row>
              <Tile role={Roles.manageCoaches} name='coaching' label='COACHING TEAM' icon='user-alt-light' pathname={route(NamedRoutes.COACHES)} />
              <Tile role={Roles.manageAthletes} name='athletes' label='ATHLETES' icon='running-light' pathname={route(NamedRoutes.ATHLETES)} />
              <Tile role={Roles.billing} name='billing' label='BILLING' icon='credit-card-light' pathname={route(NamedRoutes.BILLING)} />
              <Tile role={Roles.standard} name='support' label='SUPPORT' icon='info-circle-light' pathname={route(NamedRoutes.LANDING)} />
            </Row>
          </Col>
          <Col className={css('mt-5')} sm={4}>
            <Typography type={TType.BodyRegular}>Stats</Typography>
            <Stat name='Active Athletes' value={app.org.athletes.length} icon="running-light" />
            <Stat name='Active coaches' value={app.org.coaches.length} icon="user-alt-light" />
          </Col>
        </Row>
      </>
    )
})
export default Dashboard;