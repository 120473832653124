import {action, computed, observable, makeObservable} from 'mobx';
import api from '../../services/api';
import { UserTypes } from '../../types';
import {showToast, ToastType} from '../../DSL/Toast';

export type Invite = {
  name: string,
  orgName: string,
  userType: UserTypes
}

const emptyInvite: Invite = { name: '', orgName: '', userType: UserTypes.coach }

class Store {

  @observable
  error: boolean = false;

  @observable
  loading: boolean = true;

  @observable
  inviteId: string;

  @observable
  password: string = '';

  @observable
  invite: Invite = emptyInvite;

  @observable
  showAthleteSuccess: boolean = false;

  constructor (invite: string) {
    makeObservable(this);
    this.inviteId = invite;
    this.getInvite(invite);
  }

  getInvite = (invite: string) => {
    api(`/get-invite/${invite}`).then(action(res => {
      if (res.message) this.error = true;
      else this.invite = res.result;
      this.loading = false;
    }))
  }

  @action
  setPassword = (value: string) => this.password = value;
  
  @action
  acceptInvite = () => {
    api('/accept-invite', {
      method: 'POST',
      body: {
        invite: this.inviteId,
        password: this.password
      }
    }).then(action(res => {
      if (res.message) showToast('Error', res.message, ToastType.ERROR);
      else {
        showToast('Success', 'Account created', ToastType.SUCCESS);
        if (this.invite.userType === UserTypes.coach) {
          setTimeout(() => {
            window.location.href = '/login';
          }, 1000);
        } else {
          this.showAthleteSuccess = true;
        }
      }
    }))
  }

}

export default Store;