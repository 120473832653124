import React, {useMemo} from "react";
import classNames from "classnames";
import styles from './AcceptInvite.module.scss';
import {css} from '../../helpers/css';
import Typography, {TType} from '../../DSL/Typography/Typography';
import observeMobx from '../../observeMobx';
import {useAppStore} from '../../store/App.store';
import Colors from '../../DSL/Colors';
import AcceptInviteStore from './AcceptInvite.store';
import Input from '../../DSL/Input/Input';
import Button from '../../DSL/Button/Button';
import Icon from "../../DSL/Icon/Icon";

export interface AcceptInviteProps {
  match: {
    params: {
      inviteId: string
    }
  }
}

const AcceptInvite = observeMobx((props: AcceptInviteProps) => {
    const app = useAppStore();
    const store = useMemo(() => new AcceptInviteStore(props.match.params.inviteId), []);
    return (
      <>
      {store.showAthleteSuccess ? (
        <div className={css('w-full', 'h-full', 'd-flex', 'align-items-center', 'justify-content-center', 'flex-column')}>
          <Icon className={css(Colors.color.action)} size='7x' icon="check-circle" />
          <Typography type={TType.HeaderBold} size={45} className={css('mt-2')}>Account Created</Typography>
          <Typography type={TType.HeaderBold} size={20} secondary className={css('mt-2')}>You can now login using the AthPro mobile app</Typography>
        </div>
      ) : (
        <div>
          <Typography type={TType.HeaderExtraBold} size={25}>ATHPRO</Typography><br/>
          {store.error ? (
            <Typography type={TType.BodyRegular} size={18}>Unable to find invite</Typography>
          ) : store.loading ? (
            <div>
              <Typography type={TType.BodyRegular} size={18}>Loading Invite ...</Typography>
            </div>
          ) : (
            <div>
              <Typography type={TType.BodyRegular} size={18}>Join {store.invite.orgName} on Athpro</Typography><br/>
              <Input placeholder="Password" type="password" value={store.password} onChange={store.setPassword} size='md' className={css('mt-2')} /><br/>
              <Button onClick={store.acceptInvite} size='md' className={css('px-4', 'mt-3')}>JOIN</Button>
            </div>
          )}
        </div>
      )}
      </>
    )
})
export default AcceptInvite;