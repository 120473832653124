import {action, computed, observable, makeObservable} from 'mobx';
import {showToast, ToastType} from '../../DSL/Toast';
import api from '../../services/api';
import {AppStore} from '../../store/App.store';

type View = 'info' | 'email-code';

class Store {

  @observable
  email: string = '';

  @observable
  password: string = '';

  @observable
  name: string = '';

  @observable
  orgName: string = '';

  @observable
  view: View = 'info';

  @observable
  emailCode: string = '';
  
  @observable
  emailCodeId: string = '';

  constructor () {
    makeObservable(this);
  }

  @action
  setEmail = (value: string) => this.email = value;

  @action
  setPassword = (value: string) => this.password = value;

  @action
  setName = (value: string) => this.name = value;

  @action
  setOrgName = (value: string) => this.orgName = value;

  @action
  verifyEmail = () => {
    if (this.email === '') showToast('Email Required', 'Please input a valid email address', ToastType.ERROR);
    else if (this.password === '') showToast('Password Required', 'Please input a password', ToastType.ERROR);
    else if (this.name === '') showToast('Name Required', 'Input your name', ToastType.ERROR);
    else if (this.orgName === '') showToast('Org Name Required', 'Input the name of your organization', ToastType.ERROR);
    else {
      api('/send-email-verification-code', {
        method: 'POST',
        body: {
          destination: this.email
        }
      }).then(action((res) => {
        if (res.message) showToast('Error', res.message, ToastType.ERROR);
        else {
          showToast('Email Verification Sent', `Check your inbox`);
          this.view = 'email-code';
          this.emailCodeId = res.result.codeId;
        }
      }))
    }
  }

  @action
  setView = (view: View) => this.view = view;

  @action
  setEmailCode = (value: string) => this.emailCode = value;

  @action
  create = () => {
    api('/verify-email-code', {
      method: 'POST',
      body: {
        id: this.emailCodeId,
        code: this.emailCode
      }
    }).then(action(res => {
      if (res.message) showToast('Error', res.message, ToastType.ERROR);
      else {
        api('/create-org', {
          method: 'POST',
          body: {
            email: this.email,
            password: this.password,
            name: this.name,
            orgName: this.orgName
          }
        }).then(action(createRes => {
          console.log(createRes)
          if (createRes.message) showToast('Error', createRes.message, ToastType.ERROR);
          else AppStore.login(createRes.result);
        }))
      }
    }))
  }

}

export default Store;