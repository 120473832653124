import React from "react";
import classNames from "classnames";
import styles from './Landing.module.scss';
import {css} from '../../helpers/css';
import Typography, {TType} from '../../DSL/Typography/Typography';
import observeMobx from '../../observeMobx';
import {useAppStore} from '../../store/App.store';
import Colors from '../../DSL/Colors';
import Button from '../../DSL/Button/Button';
import {NavLink} from 'react-router-dom';

export interface LandingProps {

}

const Landing = observeMobx((props: LandingProps) => {
    const app = useAppStore();
    return (
      <>
        <div className={css('w-full', 'd-flex', 'justify-content-between', 'mt-3')}>
          <Typography type={TType.HeaderBold} size={20}>ATHPRO</Typography>
          <div className={css('d-flex')}>
            <NavLink to="/login">
              <Button className={css('px-5')} secondary>LOGIN</Button>
            </NavLink>
            <NavLink to="/create-org">
              <Button className={css('ml-3', 'px-5')}>GET STARTED</Button>
            </NavLink>
          </div>
        </div>
        <div className={css('w-full', 'text-center')} style={{marginTop: 100}}>
          <Typography type={TType.BodyRegular}>This is where the main website goes</Typography>
        </div>
      </>
    )
})
export default Landing;