// import Typography, {TType} from "../Typography/Typography";
// import { toast } from "./core";
// import Colors from "../Colors";
// import React from "react";
// import {ToastOptions} from "./types";

import {toast} from './core/toast';
import {ToastOptions} from "./types";
import Typography, {TType} from "../Typography/Typography";
import {ToastContainer} from './components/ToastContainer';
import React from 'react';
import Icon, {IconName} from "../Icon/Icon";
// import * from './types';
import "./scss/main.scss";
import {Slide} from "./components/Transitions";
import Colors from '../Colors';
import {css} from '../../helpers/css';
import styles from './index.module.scss';
// export {toast, useToast, useToastContainer, cssTransition, collapseToast, ToastContainer, Bounce, Flip, Slide, Zoom};
export {ToastContainer};

//
export enum ToastType {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    SUCCESS = 'success',
    MESSAGE = 'message'
}

export const showToast = (title: string, msg?: string, type: ToastType = ToastType.INFO, timeout = 10 * 1000) => {
    const options: ToastOptions = {};
    // timeout = 1000000;
    options.autoClose = timeout;
    options.type = type;
    options.closeOnClick = true;
    options.draggable = false;
    options.transition = Slide;
    options.position = "bottom-right";
    let icon: IconName = "info";
    let iconBbColor = Colors.bg.action;
    switch (type) {
        case ToastType.ERROR:
            icon = "times";
            iconBbColor = Colors.bg.error;
            break;
        case ToastType.SUCCESS:
            icon = "check"
            break;
        case ToastType.WARNING:
            icon = "exclamation"
            break
        case ToastType.MESSAGE:
            icon = "comment"
            break
    }

    toast(
        <div className="d-flex align-items-center">
            <div className={css(iconBbColor, 'px-3', 'py-3')}>
                <Icon className={css(Colors.color.white)} icon={icon} size="2x"/>
            </div>
            <div className='ml-2' style={{lineHeight: 1.4}}>
                <Typography className={css(Colors.color.defaultText)} type={TType.BodyRegular}>
                    <span style={{fontSize: 18}}>{title}</span><br/>
                    <span style={{fontSize: 14, opacity: 0.7}}>{msg}</span>
                </Typography>
            </div>
        </div>, options);
}
// export const showToastI18n = (msg:string,type: ToastType = ToastType.INFO, timeout = 10 * 1000)=>{
//  return showToast()
// }
export const showErrorToast = (title: string, msg: string) => {
    return showToast(title, msg, ToastType.ERROR);
}

export const debugToast = (title: string, msg: string, timeout = 1500) => {
    return showToast(title, typeof msg === "string" ? msg : JSON.stringify(msg), ToastType.WARNING, timeout);
}
