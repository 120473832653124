import http from './http';
import LocalStorage from './Local-storage';
import {showToast, ToastType} from '../DSL/Toast';

export type API_RESPONSE = {
    status: boolean,
    result?: any,
    message?: string
}

async function apiCall (endpoint: string, options?: any) {
    const accessToken = await LocalStorage.getItem('athpro-access-token');
    if (!options) options = {};
    if (!options.headers) options.headers = {};
    if (accessToken) options.headers['access-token'] = accessToken;
    if (options.method === 'POST') options.headers['Content-Type'] = 'application/json';
    if (options.body) options.body = JSON.stringify(options.body);
    // make request
    return await http(process.env.REACT_APP_API_BASE_URL + endpoint, options).catch(() => showToast('Error', 'Error getting response from server', ToastType.ERROR)) as API_RESPONSE;
}

export default apiCall;