import React from "react";
import {NavLink} from "react-router-dom";
import Container from '../../DSL/Container/Container';
import {css} from '../../helpers/css';
import Colors from '../../DSL/Colors';
import Typography, {TType} from '../../DSL/Typography/Typography';
import styles from './AuthLayout.module.scss';
import Input from '../../DSL/Input/Input';

const AppDefaultLayout = (props: any) => {
  return (
    <div className={css(styles['full-screen'], 'd-flex')}>
      <div className={css(styles['title'], 'd-flex', 'align-items-center', Colors.color.white)}>
        <Typography className={styles['title-text']} type={TType.HeaderExtraBold} size={55}>
          HABITS<br/>
          JOURNALS<br/>
          FORMS<br/>
          <Typography type={TType.HeaderRegular} size={20}>THE ATHPRO WAY</Typography>
        </Typography>
      </div>
      <div className={css(styles['content'], 'd-flex', 'align-items-center')}>
        {props.children}
      </div>
    </div>
  );
};
export default AppDefaultLayout;