import * as React from 'react';
import cx from 'classnames';
import classNames from 'classnames';

import {ProgressBar} from './ProgressBar';
import {ToastProps} from '../types';
import {DEFAULT} from '../utils/constant';
import {isFn} from "../utils/propValidator";
import {useToast} from "../hooks/useToast";
import Pane from "../../Pane/Pane";
import Colors from "../../Colors";
import styles from './Toast.module.scss';

export const Toast: React.FC<ToastProps> = props => {
    const {
        isRunning,
        preventExitTransition,
        toastRef,
        eventHandlers
    } = useToast(props);
    const {
        closeButton,
        children,
        autoClose,
        onClick,
        type,
        hideProgressBar,
        closeToast,
        transition: Transition,
        position,
        className,
        style,
        bodyClassName,
        bodyStyle,
        progressClassName,
        progressStyle,
        updateId,
        role,
        progress,
        rtl,
        toastId,
        deleteToast
    } = props;
    const cssClasses = cx(
        `${DEFAULT.CSS_NAMESPACE}__toast`,
        `${DEFAULT.CSS_NAMESPACE}__toast--${type}`,
        {
            [`${DEFAULT.CSS_NAMESPACE}__toast--rtl`]: rtl
        },
        className
    );
    const controlledProgress = !!progress;

    function renderCloseButton(closeButton: any) {
        if (!closeButton) return null;

        const props = {closeToast, type};
        if (isFn(closeButton)) return closeButton(props);
        if (React.isValidElement(closeButton))
            return React.cloneElement(closeButton, props);
    }
    
    // CUSTOM ADDITION NOT THE CLEANEST BUT WILL DO FOR NOW
    setTimeout(closeToast, 4000);

    return (
        <Transition
            in={props.in!}
            appear
            done={deleteToast}
            position={position}
            preventExitTransition={preventExitTransition}
            nodeRef={toastRef}
        >
            <Pane
                id={toastId as string}
                onClick={onClick}
                className={classNames(
                    'mx-half-gutter',
                    'my-half-gutter',
                    //'my-sm-3',
                    //'mx-sm-0',
                    //'px-2',
                    //'pt-2',
                    //'pb-2',
                    styles['toast-border'],
                    Colors.color.white,
                    Colors.bg.white, {
                        [Colors.borderColor.error]: type === 'error',
                        [Colors.borderColor.action]: type === 'info' || type === 'success',
                        //[Colors.borderColor.action]: type === 'success',
                        //[Colors.borderColor.warning]: type === 'warning',
                        //[Colors.borderColor.action]: type === 'message',
                    })}
                {...eventHandlers}
                style={style}
                ref={toastRef}
                noPadding
            >
                <div
                    {...(props.in && {role: role})}
                    className={cx(`${DEFAULT.CSS_NAMESPACE}__toast-body`)}
                    style={bodyStyle}
                >
                    {children}
                </div>
                {renderCloseButton(closeButton)}
            </Pane>
        </Transition>
    );
};
