import React, {useMemo} from "react";
import classNames from "classnames";
import styles from './Athletes.module.scss';
import {css} from '../../helpers/css';
import Typography, {TType} from '../../DSL/Typography/Typography';
import observeMobx from '../../observeMobx';
import {useAppStore} from '../../store/App.store';
import Colors from '../../DSL/Colors';
import Header from '../../DSL/Header/Header';
import AthletesStore from './Athletes.store';
import Input from '../../DSL/Input/Input';
import Button from '../../DSL/Button/Button';
import Modal from '../../DSL/Modal/Modal';
import {Table} from 'react-bootstrap';
import moment from 'moment';

export interface AthletesProps {

}

const Athletes = observeMobx((props: AthletesProps) => {
    const app = useAppStore();
    const store = useMemo(() => new AthletesStore(), [])
    return (
      <>
        <Header title="ATHLETES" centerComponent={() => (
        <div className={css('d-flex')}>
          <Input className={css('px-4')} value={store.searchValue} onChange={store.setSearchValue} placeholder='Search Athletes' />
          <Button onClick={() => store.setAddNewModal(true)} className={css('px-4', 'ml-4')}>ADD NEW ATHLETE</Button>
        </div>
      )} />
      <Table className={css('mt-5')}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Last Login</th>
          </tr>
        </thead>
        <tbody>
          {store.athletes.map((user, index: number) => (
            <tr key={index}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.lastLogin ? moment(user.lastLogin).fromNow() : ''}</td>
            </tr> 
          ))}
        </tbody>
      </Table>
      <Modal visible={store.addNewModal} setVisible={store.setAddNewModal} title="Add Athlete" footer={() => (
        <div className={css('d-flex', 'justify-content-end')}>
          <Button onClick={store.sendInvite} className={css('px-3')} size='md'>SEND INVITE</Button>
        </div>
      )}>
        <div className={css('d-flex', 'mt-3', 'justify-content-between', 'mb-4')}>
          <Input className={css(styles['new-input'])} size="md" value={store.newAthleteName} onChange={store.setNewAthleteName} placeholder='Full Name' />
          <Input className={css(styles['new-input'])} size="md" value={store.newAthleteEmail} onChange={store.setNewAthleteEmail} placeholder='Email' />
        </div>
      </Modal>
      </>
    )
})
export default Athletes;