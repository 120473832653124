import React from "react";
import Container from '../../DSL/Container/Container';
import {css} from '../../helpers/css';
import SideBar from './Sidebar';
import styles from './AppLayout.module.scss';

const AppDefaultLayout = (props: any) => {
    return (
      <div className={css('position-absolute', styles['full-screen'], 'd-flex')}>
        <SideBar/>
        <Container className={css('d-flex', 'flex-column','flex-grow')}>
          {props.children}
        </Container>
      </div>
    );
};
export default AppDefaultLayout;