import React, {useEffect, useState} from "react";
import {Redirect, Route} from "react-router-dom";
import {NamedRoutes, route} from "../App.routes";
import {useAppStore} from '../store/App.store';

export type RouteMiddleware = (_route: any) => any

const AuthMiddleware: RouteMiddleware = (_route: any) => {
  const accessToken = localStorage.getItem('athpro-access-token');
  
  if (!accessToken) return <Redirect to={route(NamedRoutes.LANDING)}/>
  return _route;
}

export default AuthMiddleware;