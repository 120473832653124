import React, {useEffect} from "react";
import {Switch} from "react-router-dom";
import routes, {AppRouteInterface} from "./App.routes";
import AppRoute from "./common/AppRoute";
import './theme/bootstrap.scss';
import './theme/bootstrap-grid.scss';
import './DSL/Colors.scss';
import './fonts/Akrobat/Akrobat.scss';
import './fonts/Roboto/Roboto.scss';
import {AppStore, AppStoreProvider} from "./store/App.store";
import 'mobx-react-lite/batchingForReactDom';
import {hot} from "react-hot-loader";
import './App.css';
import Container from './DSL/Container/Container';
import Row from './DSL/Row/Row';
import Col from './DSL/Col/Col';
import {SAD_FACE} from './assets/images'
import Typography, {TType} from './DSL/Typography/Typography';
import {ToastContainer} from "./DSL/Toast";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

AppStore.init();

const MobileWarning = () => (
  <Container className="mobile-container">
    <img src={SAD_FACE} className="sad-face"/><br/>
    <Typography type={TType.HeaderBold} size={20}>Coming Soon</Typography><br/>
    <Typography type={TType.BodyRegular} size={20}>This site is not on mobile yet ...</Typography>
  </Container>
)

function App() {
  //useEffect(AppStore.getInfo, []);
  return (
    <AppStoreProvider value={AppStore}>
      <div className="desktop-view">
        <Switch>
          {routes.map((config: AppRouteInterface) => (
            <AppRoute 
              exact={config.exact}
              key={config.name}
              path={config.path}
              layout={config.layout}
              component={config.component}
              guest={config.guest}
              middleware={config.middleware}
            />
          ))}
        </Switch>
      </div>
      <div className="mobile-view">
        <MobileWarning/>
      </div>
      <ToastContainer/>
    </AppStoreProvider>
  );
}

export default process.env.NODE_ENV === "development" ? hot(module)(App) : App