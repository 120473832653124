  
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck, faCoffee, faSearch, faEye, faChevronDown, faTrash, faInfo, faExclamationCircle, faComment, faTachometerAverage, faUserAlt, faRunning,
    faCreditCard, faCog, faSignIn
} from "@fortawesome/pro-solid-svg-icons"

import {
    faTimes,
    faTachometerAverage as faTachometerAverageLight,
    faUserAlt as faUserAltLight,
    faRunning as faRunningLight,
    faCreditCard as faCreditCardLight,
    faCog as faCogLight,
    faInfoCircle as faInfoCircleLight,
    faCheckCircle as faCheckCircleLight
} from "@fortawesome/pro-light-svg-icons"


// search for icons here: https://fontawesome.com/icons?d=gallery and https://fontawesome.com/cheatsheet
export type IconName =
    'coffee'
    | 'times'
    | 'cross'
    | 'search'
    | 'eye'
    | 'check'
    | 'chevron-down'
    | 'trash'
    | 'close'
    | 'info'
    | 'exclamation'
    | 'comment'
    | 'dashboard'
    | 'dashboard-light'
    | 'user-alt'
    | 'user-alt-light'
    | 'running'
    | 'running-light'
    | 'credit-card'
    | 'credit-card-light'
    | 'cog'
    | 'cog-light'
    | 'info-circle-light'
    | 'sign-in'
    | 'check-circle'
    ;

/**
 * FontAwesomeIcon on its own has much complex API and some of it's props/functionality may be exposed as needed by expanding `IconProps`
 */
export interface IconProps {
    icon: IconName;
    className?: string;
    fixedWidth?: boolean;
    onClick?: any;
    size?: "xs" | "lg" | "sm" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x" | undefined
}

// this is exported only to make demo page simpler, it should not be used anywhere else..
export const IconStringToComponentMapping = {
    'coffee': faCoffee,
    'times': faTimes,
    'cross': faTimes,
    'search': faSearch,
    'eye': faEye,
    'check': faCheck,
    'chevron-down': faChevronDown,
    'trash': faTrash,
    'close': faTimes,
    'info': faInfo,
    'exclamation': faExclamationCircle,
    'comment': faComment,
    'dashboard': faTachometerAverage,
    'dashboard-light': faTachometerAverageLight,
    'user-alt': faUserAlt,
    'user-alt-light': faUserAltLight,
    'running': faRunning,
    'running-light': faRunningLight,
    'credit-card': faCreditCard,
    'credit-card-light': faCreditCardLight,
    'cog': faCog,
    'cog-light': faCogLight,
    'info-circle-light': faInfoCircleLight,
    'sign-in': faSignIn,
    'check-circle': faCheckCircleLight
};

const Icon = function (props: IconProps) {
    const {icon, ...rest} = props;
    return <FontAwesomeIcon icon={IconStringToComponentMapping[icon]} {...rest}/>;
};
export default Icon;