import React from "react";
import classNames from "classnames";
import styles from './Billing.module.scss';
import {css} from '../../helpers/css';
import Typography, {TType} from '../../DSL/Typography/Typography';
import observeMobx from '../../observeMobx';
import {useAppStore} from '../../store/App.store';
import Colors from '../../DSL/Colors';

export interface BillingProps {

}

const Billing = observeMobx((props: BillingProps) => {
    const app = useAppStore();
    return <div>Hello Billing</div>
})
export default Billing;