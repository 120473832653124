import {action, computed, observable, makeObservable} from 'mobx';
import { showToast, ToastType } from '../../DSL/Toast'
import api from '../../services/api';
import {AppStore} from '../../store/App.store';

class Store {

  @observable
  email: string = '';

  @observable
  password: string = '';

  constructor () {
    makeObservable(this);
  }

  @action
  setEmail = (value: string) => this.email = value;

  @action
  setPassword = (value: string) => this.password = value;

  @action
  login = () => {
    api('/login-web', {
      method: 'POST',
      body: {
        email: this.email,
        password: this.password
      }
    }).then(action(res => {
      if (res.message) showToast('Error', res.message, ToastType.ERROR);
      else {
        AppStore.login(res.result);
        showToast('Success', 'Successfully authorized');
      }
    }))
  }

}

export default Store;