async function getItem (key: string, parsed?: boolean) {
  try {
      const result = localStorage.getItem(key);
      // @ts-ignore
      if (parsed) return JSON.parse(result);
      else return result;
  } catch (error) {
      return false
  }
}

async function setItem (key: string, value: any) {
  let parsedValue: string;
  if (typeof value === 'object') parsedValue = JSON.stringify(value);
  else parsedValue = String(value);
  try {
      localStorage.setItem(key, parsedValue);
      return true;
  } catch (error) {
      return false;
  }
}

async function removeItem (key: string) {
  try {
      localStorage.removeItem(key);
      return true;
  } catch (error) {
      return false
  }
}
export default {
  getItem, 
  setItem,
  removeItem
};