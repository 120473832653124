import {action, computed, observable, makeObservable} from 'mobx';
import { User } from '../../types'
import { showToast, ToastType } from '../../DSL/Toast';
import api from '../../services/api';
import {AppStore} from '../../store/App.store';

class Store {

  @observable
  searchValue: string = '';

  @observable
  addNewModal: boolean = false;

  @observable
  athletes: User[] = []

  @observable
  newAthleteName: string = '';

  @observable
  newAthleteEmail: string = '';

  constructor () {
    makeObservable(this)
    this.getAthletes()
  }

  @action
  getAthletes = () => {
    api('/org/athletes').then(action(res => {
      if (res.message) showToast('Error', res.message, ToastType.ERROR);
      else this.athletes = res.result.athletes;
    }))
  }

  @action
  setSearchValue = (value: string) => this.searchValue = value;

  @action
  setAddNewModal = (status: boolean) => this.addNewModal = status;

  @action
  setNewAthleteName = (value: string) => this.newAthleteName = value;

  @action
  setNewAthleteEmail = (value: string) => this.newAthleteEmail = value;

  @action
  sendInvite = () => {
    this.addNewModal = false;
    if (this.newAthleteName === '') showToast('Name required', 'Enter a athlete name');
    else if (this.newAthleteEmail === '') showToast('Email required', 'Enter a valid email');
    else {
      api('/send-invite/athlete', {
        method: "POST",
        body: {
          org: AppStore.org.id,
          name: this.newAthleteName,
          email: this.newAthleteEmail,
          roles: [],
          callbackUrl: window.location.origin
        }
      }).then(action(res => {
        if (res.message) showToast('Error', res.message, ToastType.ERROR);
        else {
          showToast('Success', 'Invite has been sent', ToastType.SUCCESS);
          this.newAthleteName = '';
          this.newAthleteEmail = '';
        }
      }))
    }
  }

}

export default Store;